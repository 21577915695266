/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useField } from 'remix-validated-form';
import { type FieldProps } from './Field.types';
import { type FieldErrorProps } from './FieldError';
import { type FieldLabelProps } from './FieldLabel';
import { type FieldWrapperProps } from './FieldWrapper';

export const useCombinedFieldProps = <T>(props: FieldProps<T>) => {
  const { getInputProps, error } = useField(props.name, props.fieldOptions);
  const fieldError = props.error || error;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    id,
    type,
    name,
    value,
    placeholder,
    autoComplete,
    label,
    onChange,
    onBlur,
    defaultValue,
    optional,
    labelComponent,
    errorComponent,
    className,
    labelProps,
    inputProps,
    inputRef,
    errorProps: _errorProps,
    fieldOptions: _fieldOptions,
    ...restProps
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = props as any;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const combinedWrapperProps: FieldWrapperProps = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    name,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    className,
    error: fieldError,
    ...restProps,
  };

  const combinedInputProps: T & {
    type: string;
    error?: string;
  } = {
    ref: inputRef,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    ...getInputProps({
      id: id || name,
      type,
      name,
      value,
      onChange,
      onBlur,
      placeholder,
      autoComplete,
      defaultValue,
      ...inputProps,
    }),
    error: fieldError,
  };

  const combinedLabelProps: FieldLabelProps = {
    htmlFor: id || name,
    children: label,
    labelComponent,
    error: fieldError,
    optional,
    ...labelProps,
  };

  const combinedErrorProps: FieldErrorProps = {
    error: fieldError,
    errorComponent,
  };

  return {
    error: fieldError,
    inputProps: combinedInputProps,
    labelProps: combinedLabelProps,
    errorProps: combinedErrorProps,
    wrapperProps: combinedWrapperProps,
  };
};
