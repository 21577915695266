import { withYup } from '@remix-validated-form/with-yup';
import clsx from 'clsx';
import { ValidatedForm, type FormProps as ValidatedFormProps, type Validator } from 'remix-validated-form';
import { type AnyObjectSchema } from 'yup';

export interface FormProps<DataType extends object, Subaction extends string | undefined>
  extends Omit<ValidatedFormProps<DataType, Subaction>, 'validator'> {
  className?: string;
  validationSchema?: AnyObjectSchema;
  validator?: Validator<DataType>;
}

export function Form<DataType extends object, Subaction extends string | undefined = undefined>({
  className,
  validator,
  validationSchema,
  children,
  ...props
}: FormProps<DataType, Subaction>) {
  const formValidator = validationSchema ? withYup(validationSchema) : validator;

  if (!formValidator) {
    console.error('Form must have either a validationSchema or validator');
    throw new Error('Form must have either a validationSchema or validator');
  }

  return (
    <ValidatedForm className={clsx('form', className)} validator={formValidator} {...props}>
      {children}
    </ValidatedForm>
  );
}
