import clsx from 'clsx';
import { type FC, type HTMLAttributes, type ReactElement } from 'react';

export type FieldErrorComponent = (
  errorProps: Omit<FieldErrorProps, 'errorComponent'>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => ReactElement<any, any> | null;

export interface FieldErrorProps extends HTMLAttributes<HTMLDivElement> {
  error?: string;
  errorComponent?: FieldErrorComponent;
}

export const FieldError: FC<FieldErrorProps> = ({ className, errorComponent, ...props }) => {
  if (errorComponent) return errorComponent(props);

  if (!props.error) return null;

  return (
    <div className={clsx('field__error mt-2 text-sm text-red-600', className)} {...props}>
      {props.error}
    </div>
  );
};
